class Component {
    constructor(element, key, props = {}) {
      this.el = element
      this.key = `${element.dataset.component}-${key}`
      this.props = props 
      this.state = null
    }
    /**
     * @method sayCurrentState
     * Console logs the current value of the state property.
     */
    sayCurrentState() {
      console.log(`The current state is ${this.state}`)
    }
      /**
     * @method sayKey
     * Console logs the current value of the key property.
     */
    sayKey() {
      console.log(`This component key is ${this.key}`)
    }
  }
  export default Component