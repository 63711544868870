import "./main.scss";
import AOS from 'aos';
import 'aos/src/sass/aos.scss'; // You can also use <link> for styles
import { ready as DOMReady } from "js/utils/DOMReady";

import { initializeComponent } from "./js/utils/InitializeComponent";

import  SliderRecipes  from "./js/components/slider-cards-recipes/index";
import  VideoWithModal  from "./js/components/video-with-modal/index";
import  SliderWithBackground  from "./js/components/slider-with-background/index";
import  SingleProduct  from "./js/components/single-product/index";
import  ProductsList  from "./js/components/product-list/index";
import  recipesList  from "./js/components/recipes-list/index";
import  Menu  from "./js/components/menu/index";
import  Faq  from "./js/components/faq/index";
import  recipiesArchive  from "./js/components/recipies/index";
import  productsArchive  from "./js/components/products/index";

AOS.init();
DOMReady(() => {

    initializeComponent('[data-component="slider-cards-recipes"]', SliderRecipes );
    initializeComponent('[data-component="video-with-modal"]', VideoWithModal );
    initializeComponent('[data-component="slider-with-background"]', SliderWithBackground );
    initializeComponent('[data-component="single-product"]', SingleProduct );
    initializeComponent('[data-component="list-of-products"]', ProductsList );
    initializeComponent('[data-component="ggy-menus"]', Menu );

    initializeComponent('[data-component="list-of-recipes-with-search"]', recipesList );
    initializeComponent('[data-component="faq"]', Faq );
    initializeComponent('#recipies-archive-component', recipiesArchive );
    initializeComponent('#products-archive-component', productsArchive );
  });

