import Component from "../../core/Component";

class SliderWithBackground extends Component {
	constructor(el, props) {
		super(el, props);
		this.el = el;
	}

	glide_slider() {
		let slider = document.querySelector("#glide_slider_peek");

		if (slider) {
			let nSlides = slider.getElementsByTagName("li").length;

			let glideSlider = new Glide(slider, {
				type: "carousel",
				perView: nSlides,
				dragThreshold: false,
				gap: 33,
				// set a value to show the previous and next slides peeking in

				breakpoints: {
					1340: {
						dragThreshold: 120,
						perView: 2,
						animationDuration: 1000,
						autoplay: 5000,
						peek: {
							before: 0,
							after: 71,
						},
					},
					900: {
						dragThreshold: 120,
						perView: 1,
						gap: 10,
						peek: {
							before: 0,
							after: 71,
						},
					},
				},
			});

			return glideSlider.mount();
		}
	}

	init() {
		this.glide_slider();
	}
}

export default SliderWithBackground;
