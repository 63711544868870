/**
 * Initialize Components based on the selector and module
 *
 * @param {selector} selector
 * @param {ClassDecorator} module
 */
 export function initializeComponent(selector, module) {
    Array.from(document.querySelectorAll(selector)).map((element, key) => {
      if (element.dataset.props) {
        return new module(element, key, element.dataset.props).init()
      }
      return new module(element, key).init()
    })
  }