import Component from "./../../core/Component";

class SliderRecipes extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
  }
  /* works only for chrome */
  /*  desktopSlider() {
    const slider = document.querySelector('.slider-content');
    let isDown = false;
    let startX;
    let scrollLeft;
    
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    }); 
    
  }
 */

  glide_slider() {
    let slider = document.querySelector(".glide_slider_recipes");

    let glideSlider = new Glide(slider, {
      type: "carousel",
      // autoplay: 4000,
      focusAt: '1',
      startAt: 0,
      perView: 4,
      gap: 200,
     
      breakpoints: {
        1700: {
          perView: 3,
          gap: 100,
        },
        1231: {
          perView: 2,
          gap: 20,
        },
        900: {
          perView: 2.3,
          gap: 0,
         
          peek: {
            before: 0,
            after: 50
        }
        },
        500: {
          perView: 1.9,
          focusAt: '1',
        },
        320: {
          perView: 1.5,
          focusAt: '1',
        },
      },
    });

    return glideSlider.mount();
  }

  init() {
    //this.glide_slider();
  }
}
export default SliderRecipes;
