import Component from "./../../core/Component";
import Vue from "../../core/Vue";
import axios from "../../core/axios";

class recipiesArchive extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
    this.component = null;
  }

  setComponent() {
    this.component = new Vue({
        /**
         * Element
         *
         * @type {string}
         */
        el: this.el,

        /**
         * Component Data
         *
         * @type {object}
         */
        data: {
            isLoading: true,
            isRequest: false,
            ajax: {
                root: '/wp-admin/admin-ajax.php'
            },
            categories: [],
            recipes: [],
            total_posts: 0,
            total_pages: 0,
            page: 1,
            showDropdown: false,
            isPagination: false,
            _q: {
                value: '',
                outputValue: '',
                timeout: null
            },
            filters: {
                q: '',
                categories: []
            },
        },

        /**
         * COmputed methods
         */
        computed: {
            /**
             * Toggle display of dropdown checkbox container
             */
            dropdownStyle: function () {
                return this.showDropdown ? 'display:block' : 'display:none'
            },

            buttonStyle: function () {
              return this.page === this.total_pages || this.total_pages === 0 ? 'opacity: 0 !important;' : ''
			},

			searchValue: function () {
				return this.filters.q
			}
        },

        /**
         * Created method.
         *
         * Executed when the  compoonent has been created
         */
        created: async function () {
            await this.getData();
            this.isLoading = false;
        },

        /**
         * Vue Methods
         */
        methods: {
            /**
             * Get main data
             */
            getData: async function () {
                this.isRequest = true
                const request = await axios.get(this.ajax.root, {
                    params: {
                        action: 'ggy_recipes',
                        s: this.filters.q,
                        categories: this.filters.categories,
                        page: this.page
                    }
                });

                if (request.data.success) {
					if (this.isPagination) {
						this.recipes = this.recipes.concat(request.data.data.recipes);
					} else {
						this.recipes = request.data.data.recipes;
					}
					this.total_posts = request.data.data.total_posts;
					this.total_pages = request.data.data.total_pages;
					this.categories = request.data.data.categories;
                }
                this.isRequest = false

			},

			search: async function () {
				this.page = 1
				await this.getData();
			},

            paginate: async function (e) {
				e.target.textContent = 'Loading...'
				this.isPagination = true

				if (this.page < this.total_pages) {
						this.page++;
						await this.getData()
				}

				e.target.textContent = 'Load More'
				this.isPagination = false
            },

            /**
             * Set the collection ids to modify only
             * specific post when user make a bulk action
             *
             * @param {Object} event | dom event
             */
            setIds: async function (event) {
                const id = parseInt(event.target.value)
                if (event.target.checked) {
                    this.filters.categories.push(id);
                } else {
                    this.filters.categories.splice(this.filters.categories.indexOf(id), 1);
                }

                // this.showDropdown = false
                await this.getData();
			},

			/**
			 * Add a like to recipe
			 *
			 * @param {Int} recipeId
			 * @param {Int} index
			 */
			addLike: async function(recipeId, index) {
				const likeRequest = await likeRecipe(recipeId);

				if (likeRequest.success) {
					this.recipes[index].likes = likeRequest.data.likes
				}
			},

            /**
             * Toggle between show or not
             * the categories dropdown
             */
            toggleDropdown: function () {
                this.showDropdown = !this.showDropdown;
            },

            /**
             * Search after wait a few seconds
             */
            inputSearch: async function() {

                // clear timeout variable
                clearTimeout(this._q.timeout);

                const _this = this;
                this._q.timeout = setTimeout(async function () {
                    _this.filters.q = _this._q.value;

                    await _this.getData();
                }, 1000);
            },
		},

		watch: {
			searchValue: async function (newValue, oldValue) {
				if (!newValue && oldValue) {
					this.page = 1
					await this.getData()
				}
			}
		}
    })
  }

  init() {
      if (document.getElementById('recipies-archive-component')) {
          this.setComponent();
      }
  }
}
export default recipiesArchive;
