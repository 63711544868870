

export default class ProductsList {
  constructor(container, options = {}) {
    this.container = container;
    this.results = this.container.querySelector('.ggy-products-results');
    this.textFilters = this.container.querySelectorAll('.ggy-products-filter-tax');


    this.parameters = {
      string: "",
      cat: "",
      sizes: []
    };
    

    const defaultOptions = {
      filterBtnsClass: '.ggy-filters-products-btn',
    };

    this.options = Object.assign({}, defaultOptions, options);
  }


  // Filter by ajax
  filterAjax() {
    const parameters = new URLSearchParams();
    parameters.set('action', 'products_ajax');
    parameters.set('string', this.parameters.string);
    parameters.set('cat', this.parameters.cat);
    parameters.set('sizes', this.parameters.sizes.join(','));
    const paramsString = parameters.toString().replace(/%2C/g, ',');

    fetch(`${wp.ajaxUrl}?${paramsString}`, {
      method: 'get',
    })
    .then((response) => {
      return response.text();
    })
    .then((html) => {
      this.results.innerHTML = html;
      const buttons = this.results.querySelectorAll(
        this.options.filterBtnsClass
      );

      this.addBtnEvents(buttons);
    })
    .catch((error) => {
      console.error(error);
    });
  }


  addBtnEvents(btns) {
    btns.forEach(btn => {
      btn.addEventListener('click', () => {
        const cat = btn.dataset.filter 
          ? btn.dataset.filter
          : this.parameters.cat;
        const sizes = btn.dataset.sizes;
        const stringValue = this.container.querySelector('#inputSearch').value;
        const validateString = stringValue ? stringValue : '';
        const validateCat = btn.dataset.filter === 'all' ? '' : cat;

        this.setParameters(validateString, validateCat, sizes);
        this.navigate();
        this.toggleCheckboxs(btn);
      })
    });
  }

  // Add underline filter text filters with 'active' class
  changeActive(btns) {
    btns.forEach(btn  => {
      // Check which filter is active when refresh the page
      const urlParams = new URLSearchParams( window.location.search );
      const filter =  btn.dataset.filter;

      if (urlParams.get('c') == filter) btn.classList.add('active');
      else if ( !urlParams.get('c') && filter == 'all' ) btn.classList.add('active');

      // Change active filter when clicked
      btn.addEventListener('click', () => {
        btns.forEach((b) => b.classList.remove('active'));
        btn.classList.add('active');
      })
    })
  }

  toggleCheckboxs (btn) {
    if (btn.querySelector('.ggy-checkbox')) {
      const cb = btn.querySelector('.ggy-checkbox'); // Find the CheckBox inside btn target 

      if ( cb.classList.contains('active') ) cb.classList.remove('active')
      else cb.classList.add('active'); 
    }
  }

  checkboxReload(p = []) { //check url for checked the checkbox filter
    if ( p ) {
      const buttons = this.container.querySelectorAll('.multipleSelection .ggy-filters-products-btn');
      const btnsFilter = []; // Empty array for all posible slugs
      buttons.forEach(e => {
        if ( e.dataset.sizes ) btnsFilter.push(e.dataset.sizes); // Create array with all posible slugs on checkbox filter
      });

      const arrayParams = p.split(',');

      arrayParams.forEach(e => {
        if( btnsFilter.indexOf(e) !== -1 )  { // find in "sizes=" parameter if exist with all posible slugs
          buttons.forEach(btn => {
            if (e == btn.dataset.sizes) { // if slug exist on checkbox options add class "active"
              const cb = btn.querySelector('.ggy-checkbox');
              
              if ( cb.classList.contains('active') ) {
                cb.classList.remove('active')
              } else {
                cb.classList.add('active')
              }; 
            };
          });
        };
      });
    }
  }

  getParameters() {
    const urlParams = new URLSearchParams( window.location.search );
    
    this.parameters.string = urlParams.get('key');
    this.parameters.cat = urlParams.get('c');
    this.parameters.sizes = urlParams.get('sizes') ? urlParams.get('sizes').split(',') : [];
    this.checkboxReload(urlParams.get('sizes'));
    //pending checkbox reload function
  }


  // Set params for string[k], category[c] or sizes[z]
  setParameters(k, c, z) {
    this.parameters.string = k;
    this.parameters.cat = c;
    const index = this.parameters.sizes.indexOf(z);

    if ( !z ) {
      this.parameters.sizes = [];
    } else {
      if ( index > -1 ) this.parameters.sizes.splice( index, 1 );
      else this.parameters.sizes.push(z);
    }
  }

  // Add params to URL or deleted
  navigate() { 
    const urlParams = new URLSearchParams(window.location.search);

    if ( this.parameters.string ) urlParams.set('key', this.parameters.string);
    else urlParams.delete('key');

    if ( this.parameters.cat ) urlParams.set('c', this.parameters.cat);
    else urlParams.delete('c');

    if ( this.parameters.sizes.length > 0 ) urlParams.set('sizes', this.parameters.sizes.join(','));
    else urlParams.delete('sizes');


    const paramsString = urlParams.toString().replace(/%2C/g, ',');


    if ( paramsString ) {
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${paramsString}`
      );
    } else {
      window.history.pushState(null, null, window.location.pathname);
    }

    window.dispatchEvent(new CustomEvent('locationchange'));
  }

  init() {
    window.addEventListener('locationchange', () => {
      this.filterAjax();
    });

    window.onpopstate = () => {
      this.filterAjax();
    };

    this.getParameters();
    this.changeActive(this.textFilters);
    



    // Enable Filters BTNs
    const buttons = this.container.querySelectorAll(
      this.options.filterBtnsClass
    );
    
    this.addBtnEvents(buttons);
  }
}
