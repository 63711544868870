import Component from "../../core/Component";

class VideoWithModal extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
  }

  my_Video(){
    let video = document.getElementsByTagName("button")[0].getAttribute("video-component");

    const myVideo = GLightbox({
      elements: [
          {
              'href': video,
              'type': 'video',
              'source': 'youtube', //vimeo, youtube or local
              'width': 900,
              'touchNavigation': false,
              'draggable':false
          },
      ],
      autoplayVideos: true,
  });

  return myVideo.open();
  }
 

  init() {
  
   document.getElementById('video-play').addEventListener('click', () => {
    this.my_Video(); 
  });

  }
}

export default VideoWithModal;
