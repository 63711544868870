import Component from "./../../core/Component";

class Menu extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
    this.open_btn = this.el.querySelector('.open_nav_btn');

  }

  toggleMenu() {
    const close_button = document.querySelector('#close_button');
    const nav_toggle = document.querySelector('#toggle-menu');
    const open_button = document.querySelector('.open_nav_btn');

    if (close_button) {
      close_button.addEventListener("click", () => {
        nav_toggle.style.display = "none";
        nav_toggle.style.transition = "1s";
        document.body.style.overflow = "inherit"
      });
    }
    
    if (open_button) {
      open_button.addEventListener("click", () => {
        if (nav_toggle.style.display === "none") {
          nav_toggle.style.display = "flex";
          document.body.style.overflow = "hidden"
          console.log('1');
        } else {
          nav_toggle.style.display = "none";
          document.body.style.overflow = "inherit"
          console.log('2');
        }
      });
    }
  }

  init() {
    this.toggleMenu();

    // disabel anchor for toggle menu
    this.open_btn.setAttribute("href", "javascript:void(0)");
  }
}
export default Menu;
