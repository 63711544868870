import Component from "./../../core/Component";

class Faq extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
  }

  checkboxConfig() {
    var el = document.getElementsByClassName("selectBox-faq")[0];
    el.onclick = function () {
      
      var show = true;
      
      var checkboxes = document.getElementById("checkBoxes-faq");
      show = checkboxes.style.display.toString() == "block" ? false : true;
      if (show) {
        checkboxes.style.display = "block";
        show = false;
        document.getElementsByClassName("selectBox-faq")[0].setAttribute('id','selectBoxUp-faq');
      } else {
        checkboxes.style.display = "none";
        show = true;
        document.getElementsByClassName("selectBox-faq")[0].setAttribute('id','selectBoxDown-faq');
      }
    };
  }




  init() {
    this.checkboxConfig();


  }
}
export default Faq;
