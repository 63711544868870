import Component from "./../../core/Component";
import Vue from "../../core/Vue";
import axios from "../../core/axios";

class productsArchive extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
    this.component = null;
  }

  setComponent() {
    this.component = new Vue({
        /**
         * Element
         *
         * @type {string}
         */
        el: this.el,

        /**
         * Component Data
         *
         * @type {object}
         */
        data: {
            isLoading: true,
            isRequest: false,
            ajax: {
                root: '/wp-admin/admin-ajax.php'
            },
            categories: [],
            sizes: [],
            products: [],
            total_posts: 0,
            total_pages: 0,
            page: 1,
            showDropdown: false,
            isPagination: false,
            _q: {
                value: '',
                outputValue: '',
                timeout: null
            },
            filters: {
                q: '',
                category: '',
                sizes: []
            },
        },

        /**
         * COmputed methods
         */
        computed: {
            /**
             * Toggle display of dropdown checkbox container
             */
            dropdownStyle: function () {
                return this.showDropdown ? 'display:block' : 'display:none'
            },

            buttonStyle: function () {
              return this.page === this.total_pages ? 'opacity: 0;' : ''
            },

            searchValue: function () {
              return this.filters.q
            }
        },

        /**
         * Created method.
         *
         * Executed when the  compoonent has been created
         */
        created: async function () {
					this.readUrlParams();
          await this.getData();
          this.isLoading = false;
        },

        /**
         * Vue Methods
         */
        methods: {
					/**
					 * Get main data
					 */
					getData: async function () {
							this.isRequest = true
							const request = await axios.get(this.ajax.root, {
									params: {
											action: 'ggy_products',
											s: this.filters.q,
											category: this.filters.category,
											sizes: this.filters.sizes,
											page: this.page
									}
							});

							if (request.data.success) {
								if (this.isPagination) {
									this.products = this.products.concat(request.data.data.products);
								} else {
									this.products = request.data.data.products;
								}
								this.total_posts = request.data.data.total_posts;
								this.total_pages = request.data.data.total_pages;
								this.categories = request.data.data.categories;
								this.sizes = request.data.data.sizes;
							}
							this.isRequest = false

					},

					readUrlParams: function () {
						let url = window.location.href
						if (url.split('?').length === 2) {
							let category = url.split('?')[1]

							if (category.split('=').includes('category')) {
								category = category.split('=')[1]
								this.filters.category = category
							}
						}
					},

					search: async function () {
						this.page = 1
						await this.getData();
					},

					paginate: async function (e) {
						e.target.textContent = 'Loading...'
						this.isPagination = true

						if (this.page < this.total_pages) {
							this.page++;
							await this.getData()
						}

						e.target.textContent = 'Load More'
						this.isPagination = false
					},

					/**
					 * Trigger filter by category
					 *
					 * @param {string} slug
					 */
					filterCategory: async function (slug) {
						this.page = 1
						this.filters.category = slug === 'all' ? '' : slug
						await this.getData()
					},

            /**
             * Set the collection ids to modify only
             * specific post when user make a bulk action
             *
             * @param {Object} event | dom event
             */
            setIds: async function (event) {
                document.getElementById('CheckId1').checked = false
                const id = parseInt(event.target.value)
                if (event.target.checked) {
                    this.filters.sizes.push(id);
                } else {
                    this.filters.sizes.splice(this.filters.sizes.indexOf(id), 1);
                }

                // this.showDropdown = false
                await this.getData();
            },

            clearSizes: async function () {
              this.filters.sizes = []
              this.page = 1
              await this.getData()
            },

            /**
             * Toggle between show or not
             * the categories dropdown
             */
            toggleDropdown: function () {
                this.showDropdown = !this.showDropdown;
            },

            /**
             * Search after wait a few seconds
             */
            inputSearch: async function() {

                // clear timeout variable
                clearTimeout(this._q.timeout);

                const _this = this;
                this._q.timeout = setTimeout(async function () {
                    _this.filters.q = _this._q.value;

                    await _this.getData();
                }, 1000);
            },
				},

				watch: {
					searchValue: async function (newValue, oldValue) {
						if (!newValue && oldValue) {
							this.page = 1
							await this.getData()
						}
					}
				}
    })
  }

  init() {
      if (document.getElementById('products-archive-component')) {
          this.setComponent();
      }
  }
}
export default productsArchive;
