import Component from "../../core/Component";

class SingleProduct extends Component {
  constructor(el, props) {
    super(el, props);
    this.el = el;
    this.actualSize;
  }

  selectedSize() {
    const size_option_a = document.getElementById("size-option-a");
    const size_option_b = document.getElementById("size-option-b");
    const size_option_c = document.getElementById("size-option-c");

    let tabcontent = document.getElementsByClassName("tabcontent");
    let tablinks = document.getElementsByClassName("tablinks");

    //hide all the content
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    this.actualSize = document.getElementById("nutrition-info-content");
    this.actualSize.style.display = "flex";
    // document.getElementById("header-actual-image").style.display = "flex";

    if (size_option_b) {
      size_option_a.addEventListener("click", () => {
        //hide all the content
        for (let i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }

        //remove active class
        for (let i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(
            "box-active",
            ""
          );
        }

        //show the actual content
        this.actualSize = document.getElementById("nutrition-info-content");
        this.actualSize.style.display = "flex";
        size_option_a.classList.add("box-active");
        document.getElementById("header-actual-image").style.display = "flex";
      });

      size_option_b.addEventListener("click", () => {
        //hide all the content
        for (let i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }

        //remove active class
        for (let i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(
            "box-active",
            ""
          );
        }

        //show the actual content
        this.actualSize = document.getElementById("nutrition-info-content-b");
        this.actualSize.style.display = "flex";
        size_option_b.classList.add("box-active");
        document.getElementById("header-actual-image-b").style.display = "flex";
      });

      if (size_option_c) {
        size_option_c.addEventListener("click", () => {
          //hide all the content
          for (let i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
          }

          //remove active class
          for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(
              "box-active",
              ""
            );
          }

          //show the actual content
          this.actualSize = document.getElementById("nutrition-info-content-c");
          this.actualSize.style.display = "flex";
          size_option_c.classList.add("box-active");
          document.getElementById("header-actual-image-c").style.display =
            "flex";
        });
      }
    }
  }

  hideNutritionFacts() {
    const nutrition_info_content_button = document.getElementById(
      "nutrition-info-content-button"
    );

    nutrition_info_content_button.addEventListener("click", () => {
      nutrition_info_content_button.classList.toggle("close");
      let temp = this.actualSize;



      if (this.actualSize.style.display === "none") {
        this.actualSize.style.display = "flex";

        setTimeout(function () {

          temp.style.opacity = "1";
          temp.style.transform = 'scale(1)';

        }, 200);
      } else {
        this.actualSize.style.opacity = "0";
        this.actualSize.style.transform = 'scale(1, 1)';


        this.actualSize.addEventListener(
          "transitionend",
          function (e) {
            temp.style.display = "none";
          },
          {
            capture: false,
            once: true,
            passive: false,
          }
        );
      }
    });
  }

  glide_slider_recommended_yogurts() {
    let slider = document.querySelector(".glide_slider_recommended_yogurts");

    let glideSliderRecommendedYogurts = new Glide(slider, {
      type: "slider",
      focusAt: "0",
      startAt: 0,
      perView: 3,
      gap: 56,

      dragThreshold: false,
      touchAngle: 0,
      // set a value to show the previous and next slides peeking in

      breakpoints: {
        900: {
          touchAngle: 45,
          dragThreshold: true,
          perView: 2,
          focusAt: "0",
          gap:0,
          peek: {
            before: 0,
            after: 40,
          },
        },
        700: {
          gap:0,
          touchAngle: 45,
          dragThreshold: true,
          perView: 1,
          peek: {
            before: 0,
            after: 40,
          },
        },
      },
    });

    return glideSliderRecommendedYogurts.mount();
  }

  glide_slider_other_products() {
    let slider2 = document.querySelector(".glide_slider_other_products");
    let slides = document.getElementsByClassName("yogurts_list");
    let whereToCenter = document.getElementById("positionToFocusAt").value;
    slides = slides - 2;
    let glideSliderotherProducts = new Glide(slider2, {
      type: "carousel",
      startAt: whereToCenter,
      perView: 3,
      focusAt: "center",
      gap:0,

      breakpoints: {
        500: {
          perView: 2.5,
          focusAt: "1",
        },
        320: {
          perView: 2,
          focusAt: "0.7",
          gap: 0,
        },
      },
    });

    return glideSliderotherProducts.mount();
  }

  init() {
    this.selectedSize();
    this.hideNutritionFacts();
    this.glide_slider_recommended_yogurts();
    //this.glide_slider_other_products();
  }
}

export default SingleProduct;
