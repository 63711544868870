export default class Filters {
  constructor(container, options = {}) {
    this.container = container;
    this.results = this.container.querySelector('.ggy-recipes-results');
    this.postType = this.container.dataset.type;
    this.parameters = {
      string: '',
      cats: []
    };

    const defaultOptions = {
      filterBtnsClass: '.ggy-filters-btn',
    };
    
    this.options = Object.assign({}, defaultOptions, options);
    
  };

  // Filter by ajax with string and category(s)
  filterAjax(s, c) {
    const parameters = new URLSearchParams();
    parameters.set('action', 'recipe_ajax');
    const paramsString = parameters.toString().replace(/%2C/g, ',');

    fetch(`${wp.ajaxUrl}?${paramsString}`, {
      method: 'get',
    })
    .then((response) => {
      return response.text();
    })
    .then((html) => {
      this.results.innerHTML = html;
      var el = document.querySelector('.ajax-load-more-wrap');
      ajaxloadmore.start(el);
      const buttons = this.results.querySelectorAll(
        this.options.filterBtnsClass
      );

      this.addBtnEvents(buttons);
    })
    .catch((error) => {
      console.error(error);
    });
  }
  
  addBtnEvents (btns) {
    btns.forEach((b, i) => {
      b.addEventListener('click', () => {
        const stringValue = this.container.querySelector('#inputBuscar').value;
        const string = stringValue ? stringValue : null;
        const cats = b.dataset.filter ? b.dataset.filter : null;

        this.setParameters(string, cats);
        this.navigate();
        this.toggleCheckboxs(b);
      });
    });
  }

  toggleCheckboxs (btn) {
    if (btn.querySelector('.ggy-checkbox')) {
      const cb = btn.querySelector('.ggy-checkbox'); // Find the CheckBox inside btn target 

      if ( cb.classList.contains('active') ) cb.classList.remove('active')
      else cb.classList.add('active'); 
    }
  }

  checkboxReload(p = []) { //check url for checked the checkbox filter
    if ( p ) {
      const buttons = this.container.querySelectorAll(
        this.options.filterBtnsClass
      );
      const btnsFilter = []; // Empty array for all posible slugs
      
      buttons.forEach(e => {
        if ( e.dataset.filter ) btnsFilter.push(e.dataset.filter); // Create array with all posible slugs on checkbox filter
      });

      const arrayParams = p.split(',');
      arrayParams.forEach(e => {
        if( btnsFilter.indexOf(e) !== -1 )  { // find in "c=" parameter if exist with all posible slugs
          buttons.forEach(btn => {
            if (e == btn.dataset.filter) { // if slug exist on checkbox options add class "active"
              const cb = btn.querySelector('.ggy-checkbox');
              if ( cb.classList.contains('active') ) cb.classList.remove('active')
              else cb.classList.add('active'); 
            }
          });
        };
      });
    }
  }

  getParameters () {
    const urlParams = new URLSearchParams(window.location.search);
    this.parameters.string = urlParams.get('s');
    this.parameters.cats = urlParams.get('c') ? urlParams.get('c').split(',') : []; 
    this.checkboxReload(urlParams.get('c'));
  }

  // Set params for string and category(s)
  setParameters(s, c) {
    this.parameters.string = s;
    const index = this.parameters.cats.indexOf(c);

    if ( !c ) {
      this.parameters.cats = [];
    } else {
      if ( index > -1 ) this.parameters.cats.splice( index, 1 );
      else this.parameters.cats.push(c);
    }
  }

  navigate () {
    const urlParams = new URLSearchParams(window.location.search);

    if ( this.parameters.string ) urlParams.set('key', this.parameters.string);
    else urlParams.delete('key');

    if ( this.parameters.cats.length > 0 )
      urlParams.set('c', this.parameters.cats.join(','))
    else urlParams.delete('c');

    const paramsString = urlParams.toString().replace(/%2C/g, ',');


    if ( paramsString ) {
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${paramsString}`
      );
    } else {
      window.history.pushState(null, null, window.location.pathname);
    }

    window.dispatchEvent(new CustomEvent('locationchange'));
  }

  init() {  
    
    window.addEventListener('locationchange', () => {
      this.filterAjax();
    });

    window.onpopstate = () => {
      this.filterAjax();
    };

    this.getParameters();

    const buttons = this.container.querySelectorAll(
      this.options.filterBtnsClass
    );

    this.addBtnEvents(buttons);
  }
}